.download-page-default {
  background-color:black;
}

.download-page-default {
  background-color:black;
}

.download-page {
  z-index: 6;
}

.download-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10vw;
    height: 100%;
    width: 25vw;
    gap: 25px; 
    text-align: center;
    float: right;
    color: white;
}

.download-title {
  margin-bottom:1rem;
}

.download-links .Download-section {
  justify-content: center;;
}

.download-kimi-signals-logo {
  height: 7%;
  margin-bottom: 3vh;
}

@media only screen and (max-width: 767px) {  
  .download-page {
    height: 105vh;
    background-size: cover;
    background-position: 50% 50%;
  }
    
  .download-container {
    margin-left: 10vw;
    width: 80vw;
    justify-content: flex-end;
    padding-bottom:5vh;
    height: calc(105vh - 5vh);
  }
}