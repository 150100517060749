.first-page {
    background-color:white;
}

.Stock-price-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 30%;
    margin-left: 10vw;
    gap: 2vh;
}

.Stock-price-phone {
    $width: 100vh;
    width: $width;
    position: absolute;
    right: min(0px, calc(90vw - $width));
    bottom: 0px;
} 

.Kimi-signals-logo {
    width: 70px;
  }

@media only screen and (max-width: 767px) {  

    .first-page {
        height: 105vh;
    }

    .Stock-price-text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: calc(100vh - 5vh);
        width: 80vw;
        margin-left: 10vw;
        padding-top: 5vh;
    }

    .Stock-price-phone {
        $width: 80vh;
        width: $width;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-35%);
    }
}