.usa-stocks-page-default  {
    background-size:cover;
    background-position: 40% 50%;
    background-color:#000000;
}

.usa-stocks-page-mobile  {
    
}

.usa-stocks-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.USA-stocks-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 30vw;
    margin-left: 10vw;
    gap: 2vh;
    color: white;
    padding-top: 3em;
}

.indicator-level-section {
    // display: flex;
    color: white;
    // justify-content: space-evenly;
    text-align: center;
    margin-bottom: 2em;
}

.indicator-level-section .table-header td {

  vertical-align: bottom;
}

.indicator-level-section .table-info td {
    vertical-align: top;
}

.table-divider {
    border-right: 1px solid white;
}

@media only screen and (max-width: 1200px) {  
    .USA-stocks-text-container {
        width: 50vw;
    }
}

@media only screen and (max-width: 767px) {  

    .usa-stocks-page {
        height: 110vh;
    }

    .USA-stocks-text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        // height: calc(105vh - 5vh);
        width: 80vw;
        margin-left: 10vw;
        gap: 15px;
        color: white;
    }
}