$gradient-cutoff: 70%;
$gradient-color-first: rgba(234,231,223,1);
$gradient-color-second: rgba(52,54,56,1);
// $gradient-color-second: red;

.indicator-level-page {
    background: rgb(234,231,223);
    background: -moz-linear-gradient(180deg, $gradient-color-first 0%, $gradient-color-first $gradient-cutoff, $gradient-color-second $gradient-cutoff, $gradient-color-second 100%);
    background: -webkit-linear-gradient(180deg, $gradient-color-first 0%, $gradient-color-first $gradient-cutoff, $gradient-color-second $gradient-cutoff, $gradient-color-second 100%);
    background: linear-gradient(180deg, $gradient-color-first 0%, $gradient-color-first $gradient-cutoff, $gradient-color-second $gradient-cutoff, $gradient-color-second 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eae7df",endColorstr="#343638",GradientType=1);
    height: auto;
}

.example-notification-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    text-align: center;
    z-index: 1;
    color: #E7DDBD;
}

.example-notification-image {
    width: 50vw;
    min-width: 300px;
    max-width: 500px;
    z-index: 1;
    position: relative;
    padding-bottom: 2em;
}

.Indicator-level-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 5vh);
    // width:80%;
    // margin-left: 10vw;
    // margin-right: 10vw;
    padding-top: 5vh;
}

.Indicator-level-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width:100%;
}
  
.Indicator-level-phones {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2vw;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    padding-bottom: 0;
    padding-top: 5vh;
    z-index: 5;
    max-width: 1650px;
}
  
.Indicator-level-phone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    scroll-snap-align: center;
    z-index: 3;
    width: 30%;
}
  
.Indicator-level-phone-image {
    position: relative;
    max-height: 45vh;
    max-width: 80%;
    width: auto;
    height: auto;
    z-index: 5;
    filter: drop-shadow(0 10px 4px #999999);
}
  
.Indicator-level-phone-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    gap: 20px;
    position: relative;
    top: -5vh;
    border-radius: 10px;
    background: white;
    width: 100%;
    z-index: 2;
    padding-top: 5vh;
}
  
.Indicator-level-logo {
    width: 50px;
}
  
.Indicator-level-phone-title {
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
  height: 2em;
}
  
.Indicator-level-phone-body {
    padding: 0px 2rem 2.5rem;
    height: 3em;
}

.Indicator-level-selection {
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-size: 25px;
}
  
.Indicator-level-selector:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 767px) {  

    .example-notification-image {
        width: 60vw;
        z-index: 1;
        position: relative;
    }

    .Indicator-level-phone-container {
        width: auto;
    }

    .Indicator-level-text-container {
        gap: 10vw;
    }

    .Indicator-level-title-container {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding:0;
        
    }

    .Indicator-level-phones {
        justify-content: left;
        gap: 5vw;
        width: 90vw;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .Indicator-level-phone-bg {
        top: -10vw;
        padding-top: 15vw;
        width:60vw;
    }

    // .Indicator-level-phone-image {
    //     position: relative;
    //     height: 75vw;
    //     z-index: 5;
    //     filter: drop-shadow(0 10px 4px #999999);
    // }
}