.market-signals-page {
  background-color:#EAE7DF;
}

.Market-signals-phones {
    $width: 100vh;
    width: $width;
    position: absolute;
    left: min(0px, calc(85vw - $width));
    bottom: 0px;
}

.Market-signals-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 30%;
    gap: 2vh;
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: 10vw;
  }  

@media only screen and (max-width: 767px) {  

  .market-signals-page {
    height: 105vh;
  }

  .Market-signals-phones {
    $width: 80vh;
    width: $width;
    position: absolute;
    left: 50%;
    bottom: 0px;
  }

  .Market-signals-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(105vh - 5vh);
    width: 80vw;
    margin-left: 10vw;
    gap: 2vh;
    padding-top:5vh;
    
  }
}