.dark-mode-page {
  max-height:100vh;
}

.Dark-mode-title {
  text-align: center;
  padding-top: 5vh;
  transition: color 1s;
  color: #E7DDBD;
}

.Dark-mode-title-light {
  color: #343638;
}

.Dark-mode-phones {
  max-height: 80vh;
  max-width: 95vw;
  height: auto;
  height: auto;
  position: absolute;
  left: 50%;
  bottom: 0px;
  z-index: 3;
}

.Dark-mode-phones-shadow {
  position: absolute;
  bottom: 0px;
  z-index: 2;
}
  
.Dark-mode-light-bg {
  width: 100vw;
  height: 50%;
  background-color: #EAE7DF;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.Dark-mode-switch-container {
  padding-top: 5vh;
  color: #E7DDBD;
  text-align: center;
  position: relative;
  z-index: 5;
}

.Dark-mode-text-container {
  display: flex;
  justify-content: space-between;
  padding-top: 3vh;
  color: white;
}

.Dark-mode-left-text {
  width: 25vw;
  margin-left: 10vw;
  transition: color 1s;
}

.Dark-mode-right-text {
  width: 25vw;
  text-align: right;
  float: right;
  margin-right: 10vw;
  transition: color 1s;
}

@media only screen and (max-width: 767px) {  

  .dark-mode-page {
    height: 100vh;
  }

  .Dark-mode-switch-container {
    padding-top: 5vh;
  }

  .Dark-mode-phones {
    max-height: 85vh;
    max-width: 85vw;
    height: auto;
    height: auto;
    position: absolute;
    left: 50%;
    bottom: 0px;
    z-index: 3;
  }

  .Dark-mode-text-container {
    display: flex;
    justify-content: space-between;
    padding-top: 3vh;
    gap:10vw;
  }

  .Dark-mode-left-text, .Dark-mode-right-text {
    width: 30vw;
  }
}
