.browser-warning-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.browser-warning-title {
    font-size: 30px;
}

.browser-warning-body {
    font-size: 20px;
}

.browser-warning-icons {
    display: flex;
}

.browser-warning-icon {
    height:7vh;
    padding: 10px;
}