@import url("https://use.typekit.net/ykw5vxf.css");

// @tailwind base;
@tailwind components;
@tailwind utilities;

//Defining variables
$base-font-size: 16px;
$header-height: 100px;
$black: #222222;
$tan: #E9DDB9;
$orange: #E7783C;
$title-xlarge: 4.25rem; 
$title-large: 3.75rem;
$title-medium: 2.875rem;
$title-small: 2.2rem;
$paragraph-feature: 1.625rem;

html, body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: $base-font-size;
}

.title-xlarge {
  font-size: $title-xlarge;
  line-height: 1.025 * $title-xlarge;
  margin:0 0 1rem 0;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.title-large {
  font-size: $title-large;
  line-height: 1.025 * $title-large;
  margin: 0;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.title-medium {
  font-size: $title-medium;
  line-height: 1.125 * $title-medium;
  margin:0;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.title-small {
  font-size: $title-small;
  line-height: 1.025 * $title-small;
  margin:0;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.page-subtitle {
  font-size: $paragraph-feature;
  line-height: 1.5 * $paragraph-feature;
  font-weight: bolder;
}

h1, h2, h3, h4, h5 {
  padding:0;
}

p {
  margin:0 0 1rem 0;
}

hr {
  background-color: white;
  // width: 2px;
}

.App-header {
  background-color: $tan;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $black;
  height: $header-height;
}

.App-title{
  text-decoration: none;
  color: $black;
  font-size: 1.25rem;
  font-weight:600;
  letter-spacing:4px;
}

.App-title:hover{
  text-decoration: none;
}

.Page-container {
  position: relative;
  min-height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
}

.Content-wrap {
  background-color: #EAE7DF;
  flex: 1 1 auto;
}

.App {
  display: flex;
  flex-direction: column;
}

.App-footer {
  background-color: #E9DDB9;
  color: black;
  width: 100%;
  font-weight:400;
  letter-spacing:1px;
  flex: 0 1 auto;
}

.Footer-nav-links {
  display: flex;
  // gap: 10vw;
  justify-content: space-around;
  align-items: center;
  padding: 4em 8em;
}

.Footer-link {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.Footer-link:hover {
  text-decoration: underline;
}

.Footer-copyright {
  width: 100%;
  text-align: center;
  padding:0 0 2em 0;
}

.footer-kimi-signals-logo {
  height:7vh;
}

.App-page {
  height: calc(100vh - $header-height);
  position: relative;
  background-position: 60% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-color 1s, color 1s;
}

.Download-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}

.App-download-link {
  height: 50px
}

.App-download-link:hover {
  cursor: pointer;
}

.Orange-text {
  color: $orange;
}

.Ital-text {
  font-style: italic;
}

.feedback-page {
}

.feedback-container {
  margin: 0 2em;
  padding-bottom: 2em;
  max-width: 767px;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
}

.feedback-images-upload-container {
  background-color: white;
  height: 300px;
  margin: 1em 0;
  border: 1px solid black;
  padding: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback-images-upload-container:hover {
  border: 2px solid black;
}

.feedback-images-uploaded {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
  height: 100%;
}

.feedback-image {
  max-height: 100px;
}

.feedback-images-upload-instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.feedback-image-container {
  width: 100px;
  height: 100px;
  border: 1px solid black;
}

.feedback-image {
  position: relative;
}

.feedback-image-delete {
  position: absolute!important;
  top: -20px;
  right: -20px;
}

.feedback-form-submit {
  width: 100%;
}


@media only screen and (max-width: 1000px) {  
  .Indicator-level-phone-title {
      font-size: 0.8 * $title-medium !important;
      line-height: 0.925 * $title-medium !important;
  }

  .Indicator-level-phone-body {
    font-size: 0.8 * $paragraph-feature;
    line-height: 0.9 * $paragraph-feature;
  }
}

@media only screen and (max-width: 767px) {
  .title-xlarge {
    font-size: 0.8 * $title-xlarge;
    line-height: 0.825 * $title-xlarge;
    margin: 0;
    font-family: futura-pt, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .title-large {
    font-size: 0.8 * $title-large;
    line-height: 0.825 * $title-large;
    margin: 0;
    font-family: futura-pt, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .title-medium {
    font-size: 0.8 * $title-medium;
    line-height: 0.925 * $title-medium;
    margin:0;
    font-family: futura-pt, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .title-small {
    font-size: 0.8 * $title-small;
    line-height: 0.925 * $title-small;
    margin: 0;
    font-family: futura-pt, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .page-subtitle {
    font-size: 0.8 * $paragraph-feature;
    line-height: 1.3 * $paragraph-feature;
    font-weight: bolder;
  }

  .App-download-link {
    height: 45px;
  }
  
  .footer-kimi-signals-logo {
    padding-bottom:2vh;
  }

  .Footer-nav-links {
    flex-direction: column;
    // gap: 5vw;
    padding: 2em 0;
  }

  .Footer-copyright {
    padding:0 0 2em 0;
  }
  
}